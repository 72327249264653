import anime from "animejs";

import { $, $$, rect } from "@utils/dom";
import Viewport from "@utils/viewport";

const SELECTOR = "[data-site-transition]";

function inViewport(el) {
  if (!el) return false;

  const { top } = rect(el);
  return top <= Viewport.height;
}

class SiteTransition {
  constructor() {
    this.el = $(SELECTOR);
  }


  exit() {
    this.el.classList.remove("pointer-events-none");

    return new Promise((resolve) => {
      anime({
        targets: this.el,
        opacity: [0, 1],
        delay: 150,
        duration: 450,
        easing: "linear",
        complete: () => resolve()
      });

      this.el.classList.remove("visibility-hidden");
    });
  }

  entering() {
    // increment --row-delay css variable to each .pb-row-wrapper[data-scroll-section] in viewport during initialization
    [ ...$$(`main .pb-row-wrapper[data-scroll-section]`) ].forEach((el, index) => {
      if (inViewport(el)) el.style.setProperty("--row-delay", `${index * 650}ms`);
    });
  }

  enter() {
    return new Promise((resolve) => {
      anime({
        targets: this.el,
        opacity: [1, 0],
        duration: 150,
        easing: "linear",
        complete: () => {
          this.el.classList.add("visibility-hidden", "pointer-events-none");
          resolve();
        }
      });
    });
  }
}

export default SiteTransition;
